<template>
  <div class="receive-record">
    <div class="null" v-if="list.length == 0">
      <img
        class="null-icon"
        src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/recordNull.png"
      />
      <div class="null-msg">暂无领取记录~</div>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      @load="onLoad"
      loading-text="正在加载..."
    >
      <div class="box" v-for="(item, index) in list" :key="index">
        <div class="box-top">
          <div class="title">
            <img :src="item.item_small_icon" />
            <span class="name">{{ item.item_name }}</span>
          </div>
          <div class="more" @click="showExplain(item.item_name)">
            <span class="text">使用说明</span>
            <img
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/icon_more.png"
            />
          </div>
        </div>
        <div class="box-cont">
          <div class="box-cont-text">
            <div class="line">领取号码：{{ item.phone_number }}</div>
            <div class="line">领取时间：{{ item.exchange_time }}</div>
            <div class="line" v-if="item.exchange_number">
              卡号：{{ item.exchange_number }}<span class="border" /><span
                class="copy"
                @click="copyToClipboard(item.exchange_number, '卡号')"
                >复制</span
              >
            </div>
            <div class="line" v-if="item.exchange_password">
              {{ !!item.exchange_number ? "密码" : "兑换码" }}：{{
                item.exchange_password
              }}
              <span class="border"></span>
              <span
                class="copy"
                @click="
                  copyToClipboard(
                    item.exchange_password,
                    !!item.exchange_number ? '密码' : '兑换码'
                  )
                "
              >
                复制
              </span>
            </div>
          </div>
          <div class="box-cont-btn" v-if="item.exchange_link">
            <van-button class="submit" @click="handleUseNow(item.exchange_link)"
              >立即使用</van-button
            >
          </div>
        </div>
      </div>
    </van-list>
    <v-alert
      :visibility.sync="alert.visibility"
      :title="alert.title"
      :message="alert.message"
    />
  </div>
</template>

<script>
import VAlert from "./dialog/alert.vue";
import { record } from "@/api/carOwner_zhengxing/receive.js";
import { mapGetters } from "vuex";

export default {
  components: {
    VAlert,
  },
  data() {
    return {
      list: [],
      alert: {
        visibility: false,
        title: "",
        message: "",
      },
      loading: false, // 控制加载状态
      finished: false, // 是否已全部加载完毕
      page: 0, // 当前页数，默认从第1页开始
      // pageSize: 20, // 每页显示的数据条数
    };
  },
  computed: {
    ...mapGetters(["carOwner_zhengxing_token"]),
  },
  created() {
    // this.getList();
  },
  methods: {
    async onLoad() {
      this.loading = true;

      try {
        this.page++; // 更新当前页数，准备请求下一页数据
        const res = await this.getList();
        this.list = [...this.list, ...res.record];
        // 检查是否有更多数据，可以根据 res 中的 meta 或者其他字段判断
        if (this.page == res.pages) {
          // 加载完毕
          this.finished = true;
        } else {
          this.finished = false;
        }
      } catch (error) {
        // console.error("加载失败:", error);
        this.$router.replace("/carOwner_zhengxing/receive/login");
      } finally {
        this.loading = false;
      }
    },
    async getList() {
      try {
        const res = await record(this.carOwner_zhengxing_token, {
          page: this.page,
          data_type: "exchange",
        });
        return res;
      } catch (error) {
        this.finished = true;
      }
    },
    copyToClipboard(text, name) {
      const textarea = document.createElement("textarea");
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";

      document.body.appendChild(textarea);
      textarea.value = text;
      textarea.select();

      try {
        document.execCommand("copy");
        this.$toast.success(`${name}已复制到剪贴板`);
      } catch (err) {
        this.$toast.error(`复制${name}失败，请手动复制`);
      } finally {
        document.body.removeChild(textarea);
      }
    },
    showExplain(name) {
      const data = {
        车主通用券: `1.本红包仅支持支付宝车生活相关的加油、充电、高速
MTC、停车场景，以及支付宝代驾小程序、支付宝租车小程序、支付宝洗车小程序、ETC服务小程序使用。车主红包具体支持核销场景及核销规则如下：
(1)加油：本红包油卡支付不可用。红包使用范围：用户可在支付宝首页【出行-车生活-加油】展示的油站完成一键加油或选择对应油枪的线上订单，并选择支付宝在线支付时使用。
(2)充电：红包仅限在支付宝-首页【出行】-【车生活】-【充电】展示的充电品牌充电站使用，仅适用于汽车充电订单。
(3)高速MTC:红包仅限在走高速人工通道缴过路费时【使用支付宝支付】可用。
(4)停车：1.用户进入【支付宝停车】小程序，输入车牌号查询缴费，支付时自动抵扣；2.支持全国支付宝缴费停车场支付使用(可至支付宝APP-“附近停车”小程序-推荐停车场查看停车场分布);
(5)代驾：1.打开支付宝首页【出行-车生活-代驾】,下单呼叫代驾，完成订单支付时直接抵扣；2.在支付宝首页搜索“支付宝代驾”,点击进入【支付宝代驾】服务，下单呼叫代驾，完成订单支付时直接抵扣；
(6)租车：用户可在【支付宝租车】小程序中选择展示红包优惠的车辆使用，仅限年满18周岁拥有驾照的用户租车使用。
(7)洗车：1.打开支付宝首页【出行-车生活-特价洗
车】,下单使用；2.在支付宝首页搜索“支付宝特价洗车”,点击进入【支付宝特价洗车】服务，下单使用；
(8)ETC:仅限在支付宝小程序「ETC服务」办理客车ETC套餐使用(不包含建行免费款)。支付客车ETC办理费用时自动抵扣。
2.红包在交易时支持叠加使用，最多叠加10个。本红包不可兑换现金，不可找零。
3.权益使用有效期自领取之时起至30*24h。若使用红包的交易发生退款，红包将退回到原支付宝账号，使用有效期不变，退还红包在原有效期内可继续使用；如在原有效期外，则红包失效，不支持再次使用。
4.本红包仅限在中国大陆地区使用。本红包限实名认证用户才可使用。
5.如对本活动有疑问的，可通过官方热线4001180238进行咨询。
6.如果用户存在或涉嫌违法违规行为情况(包括不限于洗钱、虚假交易、作弊、套现、赌博、刷信用、支付宝基于自身风险政策或策略认定的风险交易等)的，支付宝将撤销用户活动参与资格和奖品使用资格，并有权回收用户已经获得的奖励权益(包括已消费金额)。请特别关注：淘宝集市购买Q币点卡、影视会员业务、游戏视频网站的一键支付业务、无卡支付业务、货到付款业务、外部商户独立担保交易等业务无法使用红包支付。
7.活动主办方可能需要根据活动的实际举办情况对活动规则依法进行变更；若有变更，活动主办方将以活动页面告知等方式及时通知；
8.本活动开展期间，如出现不可抗力等情况，例如发生自然灾害事件、遭受网络攻击或电信故障、停机维护、
疫情、活动受法律法规、监管机构要求或政策指令需要LII停止举办或调整，活动主办方单方暂停、中止或终止提供服务的，可免于承担责任。请特别关注：淘宝集市购买Q币点卡、影视会员业务、游戏视频网站的一键支付业
务、无卡支付业务、货到付款业务、外部商户独立担保交易等业务无法使用红包支付!`,
        滴滴代驾: `1.代驾券仅限滴滴出行 APP 或微信客户端内代驾业务线上支付时使用(代驾券不可抵扣司机报单订单、路桥费、纯等候费)
2.代驾券仅限在滴滴出行-代驾业务开通的城市使用。
3.代金券充值自领取之日起有 30 天的有效，过期不退不换:滴滴订单时间需在券到账时间之后方可用，券到账前已经开始的订单无法使用
4.只能用于支付券码到帐滴滴钱包后发起的滴滴订单，在领取券码前已发起的订单无法支付`,
        滴滴打车券: `1.本品为滴滴出行快车代金券直充，仅支持账号绑定手机号码充值。请用户在充值前确保号码已开通滴滴账号。
2.虚拟商品，一经提交充值不支持退换。
3.一笔订单只能使用一张优惠券，并且只能抵扣充值到账户之后的生成的订单，优惠券不拆分、不找零，请合理选择是否使用优惠券，优惠不足部分需用电子支付方式补足。拼车、特惠快车无法使用。
4.到账后有效期30天，请及时使用`,
        滴滴专车券: `1.本品为滴滴出行专车代金券直充，仅支持账号绑定手机号码充值。
2.虚拟商品，一经提交充值不支持退换。
3.一笔订单只能使用一张优惠券，并且只能抵扣充值到账户之后生成的订单，优惠券不拆分、不找零，请合理选择是否使用优惠券。
优惠券选券排序
① 优先选可抵扣金额最大的
② 可抵扣金额一样的情况下，选未用尽金额更小的
③ 可抵扣金额一样，未用尽金额 一样，选择过期时间更近的
4.到账后有效期30天，请及时使用`,
        高德打车券: `高德打车
1.注册高德的手机号提交充值，本券仅限在高德地图中使用。
2.仅限在高德地图端内打车时使用，出租车、一口价订单不能使用。
3.打车券有效期为充值成功之日起7天内有效，请在券有效期内使用，过期失效作废。
4.同一订单优惠券最多可使用一张，不可叠加使用;券仅限一次性使用，不可拆分，不可转让，不可提现。
5.充值成功后，登录[高德地图] app，点击[我的]-[钱包卡券]-[券]处查看使用规则及有效期情况。
6.对券的使用等有任何疑问可以拨打客服电话: 4001180238
7.到账后有效期7天，请及时使用`,

        // -----------美食----------------
        奈雪的茶代金券: `1、输入奈雪的茶注册手机号码提交充值，充值成功后可在“奈雪点单”小程序-我的-奈雪券中查看使用；
2、电子券为一次性产品，不可分次使用，不可兑换现金，不找零，不与其他优惠同享。
3、请确定充值账号正确性；虚拟商品，一经充值成功，无法退货。
4、充值到账户有效期30天，请及时使用，过期作废`,
        霸王茶姬代金券: `1、【兑券时间】请于券面标注的效期前在”霸王茶姬”微信或支付宝小程序进行兑券。成功领取后，用户可在“【霸王茶姬点单小程序】-【我的】-【优惠券】”中查看券使用范围等具体使用规则并使用相关权益。
2、【适用商品】全品类：所有标准单杯茶饮品类，不包括霸王周边产品、套餐产品（门店供应的茶饮产品类型，以各门店实际情况为准）
3、【适用门店】全国区域已开通“霸王茶姬点单小程序”的门店使用(部分高铁、机场、景区门店不可用)
4、【适用场景】本券支持“霸王茶姬点单小程序”【门店自取】 、【外卖点单】订单可用，不支持【门店线下核销】
5、【券有效期】自兑券之日起30个自然日内有效，请及时使用，过期作废。`,
        瑞幸咖啡代金券: `1.输入瑞幸咖啡绑定的手机号充值，仅限于luckin coffee APP内购买饮品使用。
2.充值成功后将在10分钟内到账，请到“luckin coffee”APP-我的-咖啡钱包中查看使用。
3.瑞幸咖啡饮品券适用于自制饮品，大师咖啡、水果茶等。饮品套餐、甜品 、烘焙轻食、零食、果汁、坚果、酸奶、周边潮品等不能使用。
4.瑞幸咖啡饮品券可抵扣相应面额的商品费用（注意是商品原价，如23元代金券，只能兑换原价23元的商品），但不包含风味糖浆、奶油等辅料及配送费，优惠不同享，不设找零，超额需要补差价，一旦充值成功，不退换。
5、有效期30天以内，有效期请以券面数据为准，请及时使用，过期作废。 `,
        库迪咖啡代金券: `1.输入库迪咖啡绑定的手机号充值，即可充值到该用户订购时的中国大陆地区手机号绑定的库迪咖啡账户内。
2. 若充值手机号未注册绑定的库迪咖啡账号，在充值成功后，库迪咖啡将会为您自动注册会员，用户可直接打开库迪咖啡微信小程序 /APP，选择“我的 - 代金券”即可查看权益
3，本产品为虚拟产品，一经充值成功后无法进行退换。
4.结算时，本品可抵扣全场任意饮品相应面额的商品费用，但不包含风味糖浆、小食甜点、美食热狗、早餐套餐及配送费。
5.每张饮品券只能抵扣一杯饮品相应面额的商品费用，不能拆分为多次使用。在一张订单中购买多杯饮品时，可以使用多张饮品券，每杯饮品仅限使用一张饮品券。
6、饮品券有效期为自充值成功之日起 30 天，请及时使用，过期作废。`,
        喜茶代金券: `1、微信搜索“喜茶GO”喜茶GO点击“我的”—“兑换中心-喜茶券”，输入兑换码后即可兑换喜茶券。兑换成功后，用户可前往我的“我的”—“喜茶券”中进行查看及使用。
2、同一兑换码仅可兑换一张喜茶券，兑换成功后兑换码即失效，不可重复兑换。
3、兑换码兑换成功后可在喜茶GO小程序直接下单使用，也可在线下出示会员码进行使用。
4、优惠券有效期为30天，请及时使用，过期作废。`,
        美团外卖代金券: `1.支持美团外卖绑定手机号充值。
2.充值成功后，请到“美团外卖”APP中“我的”-“红包”中查看使用。
3、外卖券与其他优惠不同享，不可叠加使用，不支持到店自取，限登录和收餐手机号为充值号码使用。一个外卖订单只可用一张优惠券。
4、虚拟商品，一经充值提交，不支持退换。
5、外卖券有效期30天，请及时使用，过期作废。`,
        周黑鸭代金券: `1、复制兑换码，搜索并关注“周黑鸭官方旗舰店”微信公众号，点击底部菜单栏：【兑换专区】-代金券专区，选择专区商品；点击【购物车】-【去结算】，输入收货地址，点击【优惠券/优惠码】-在【请输入兑换码】处粘贴兑换码并点击【使用】，获得优惠券；提交订单并完成支付，即可享受优惠。
2、本优惠券新老用户均可使用，每个ID限领10张，不与其他优惠叠加，每笔订单限用一张；
3、物流信息可在“周黑鸭官方旗舰店”微信公众号【进入商城】-【个人中心】-【我的订单】中查看；
4、本优惠券仅购买原价商品时可用；
5、本优惠券不可二次销售，不可兑现；
6、内蒙、青海、宁夏、新疆、西藏、港澳台不发货地区不发货，不可使用本优惠券。
7、订单退款，默认不返还优惠券，请谨慎操作退款。
8、优惠券效期为30天，请及时使用，过期作废。`,
        满记甜品代金券: `1.本券为二维码形式，需到店出示二维码由门店扫描验证使用。
2.本券仅限抵扣满记甜品正价商品，不与其他优惠同享，不兑现不找零
3.本券不可用于会员卡充值、积分、兑换现金、不找零、不记名、不挂失、用券部分不开具发票、不足部分由现金补足
4.每单仅限使用一次，核销后即失效，截屏、复制、修改无效
5.本券不适用于外送服务，仅限到店使用。
6.本券一旦核销，如有退款，优惠金额不返还
7.抵用优惠部分不另开具发票
8.本券可以在满记甜品全国直营门店使用，加盟店和机场高铁店等特殊门店不支持使用，敬请谅解（不可用门店包括不限于安徽宣城万达店、安徽郎溪国购店、安徽蚌埠银泰城店、安徽安庆八佰伴店、安徽安庆吾悦店、安徽合肥滨湖店、甘肃天水万达店、安徽阜阳商厦时代广场店、江苏沭阳商贸城店、上海松江欢乐谷店、山东菏泽万达店、浙江金华世茂店、广州天河城店、江西景德镇陶溪川店、河北廊坊水云间店、河北沧州吾悦店、湖州安吉万象店、山东威海威高店、江苏南京华采店、黑龙江牡丹江东一店等），消费前请确认门店。
9、本券有效期30天，请及时使用，过期作废。`,
        百果园代金券: `1、账号绑定手机号充值，充值成功后可登录百果园APP-我的-优惠券查看;
2、本券可直接使用券码在全国百果园线下品牌门店消费使用;
3、每张券需一次性消费完(消费金额需≧券面金额)，单笔订单可叠加使用多张代金券;
4、本电子代金券不记名，售出不退换、不挂失、不能兑换现金;
5、适用范围为百果园全国线下品牌门店、百果园App、百果园小程序，如遇线下门店关闭、装修等停止营业的，以门店实际情况为准，不再另行通知。
6、兑换成功后的有效期为15天，请及时使用，过期作废。`,
        良品铺子代金券: `1.此卡仅兑付良品铺子门店及APP商城内在售商品（暂不支持支付外卖商品）
2.打开良品铺子APP：我的-礼品卡-添加礼品卡，输入兑换码兑换卡券。
3.兑换成功后，在APP商城购物结算时选择支付方式为【礼品卡支付】。
4.门店购物结算时点开礼品卡二维码出示给店员按【消费卡】核销。
5.APP商城消费，单笔只能选用1张礼品卡支付（单笔支持混合支付）。
6.门店消费，单笔可使用多张礼品卡支付。
7.使用礼品卡购物支付后，不支持退货退款流程。
8、本券有效期30天，请及时使用，过期作废。`,
        肯德基代金券: `1.本券为二维码形式，需到店出示二维码由门店扫描验证使用。
2.不可拆分使用，不支持外卖点餐、手机点餐，消费金额超过代金券面值时抵扣使用。
3.肯德基全国直营门店可用（汽车站，火车站，机场等交通枢纽和景区门店除外）。
4.代金券有效期30天，请及时使用，过期作废。`,
        必胜客代金券: `1、本券为二维码形式，需到店出示二维码由门店扫描验证使用。
2、不可拆分使用，不支持外卖点餐、手机点餐，消费金额超过代金券面值时抵扣使用。
3、必胜客全国直营门店可用（汽车站，火车站，机场等交通枢纽和景区门店除外）。
4、代金券有效期30天，请及时使用，过期作废。`,
        麦当劳代金券: `1.【绑定方式】：本券仅限登录麦当劳官方App、麦当劳微信小程序和麦当劳支付宝小程序，点击“我的”，选择“兑换券”输入兑换码即完成兑换后方可使用。本电子券仅能一次性兑换。                                                                                
2.【使用方式】：请于点餐时登录麦当劳官方App、麦当劳微信小程序或麦当劳支付宝小程序的到店取餐或麦乐送功能的点餐页面下单使用本电子券。
3.【谨慎保管】：本券不记名，不可充值，不找零，不支持挂失。请注意保管，谨防遗失或泄露。
4.【发票】：使用本电子券消费时，麦当劳餐厅不再提供发票。
5.【不可转赠】：本电子券不支持转赠。
6.【优惠不叠加】：本电子券不可以与已享受其他优惠同时叠加优惠使用
7、【适用门店】：本电子券仅限于中国（本活动场景下不包括港澳台地区）部分麦当劳餐厅使用，机场、火车站等交通枢纽餐厅、旅游景区餐厅等特殊餐厅除外，详询门店。每家餐厅营业时间有所不同，以门店实际为准。
8、本券有效期30天，请及时使用，过期作废。`,
        猫眼电影代金券: `1.登录猫眼电影APP-我的-优惠券-绑定，限电影选座使用；购票时，请在“活动与抵用券”，选择使用该券，不可叠加，不能与优惠同享；成功下单后抵用券作废。
2.代金券需在票面价格【5.01】元或以上可以使用；
3.该商品不支持退券、换券；兑换码是否有效以核实为准。
4、代金券同一批券码每个账户限绑定1张（同一账号、手机号、设备、或支付账号相同，即视为同一账户）绑定后7天内有效，请及时使用，过期作废。
5、本券有效期30天，请及时使用，过期作废。`,

        // -----------车主畅享会员----------------
        腾讯视频: `1.充值成功后，打开腾讯视频APP-使用手机号登录。
2.本商品不支持电视，仅支持手机、电脑 、平板三端。
3.本券到账后有效期30天，过期作废，请及时使用。`,
        爱奇艺: `1.充值成功后，打开爱奇艺APP-使用手机号登录。
2.本会员不支持电视端使用，仅支持手机、电脑和平板三端使用。
3.本券到账后有效期30天，过期作废，请及时使用。`,
        优酷视频: `1.充值成功后，打开优酷视频APP-使用手机号登录。
2.本商品不支持电视端，仅支持手机、电脑、平板使用。
3.PC端会员同一时间只能登录一个会员设备。
4.本券到账后有效期30天，过期作废，请及时使用。`,
        哔哩哔哩: `1.充值成功后，打开哔哩哔哩APP-使用手机号登录。
2.本品仅支持手机、电脑、平板使用，不支持电视端。
3.本券到账后有效期30天，过期作废，请及时使用。`,
        芒果TV: `1.充值成功后，打开芒果TV APP-使用手机号登录。
2.适用平台：仅支持手机 、平板、电脑三端。
3.本券到账后有效期30天，过期作废，请及时使用。`,
        喜马拉雅: `1.充值成功后，打开喜马拉雅APP-使用手机号登录。
2.请确定充值账号的正确性，虚拟商品，一经充值成功，无法退货。
3.本券到账后有效期30天，过期作废，请及时使用。`,
        网易云音乐: `1.请使用充值号码（充值号码与绑定号码需一致），登陆网易云音乐APP使用。
2.本券到账后有效期30天，过期作废，请及时使用。`,
        懒人听书: `	1.本商品为懒人听书月卡直充,支持在懒人听书APP上享受会员权益。
2.充值成功后，打开懒人听书APP使用手机号登陆。
3.到账后有效期30天，过期作废，请及时使用。`,
        酷我音乐: `1.充值成功后，打开酷我音乐 APP-使用手机号登录。
2.本月卡包含权益以酷我APP展示为准。
3.本券到账后有效期30天，过期作废，请及时使用。`,
        酷狗音乐: `1.登录酷狗音乐APP-我的或电脑端头像查看到账信息；具体会员权益以APP展示为准。
2.本券到账后有效期30天，过期作废，请及时使用。`,
      };
      this.alert = {
        visibility: true,
        title: `${name}`,
        message: data[name],
      };
    },
    handleUseNow(link = "") {
      location.href = link;
    },
  },
};
</script>

<style lang="scss" scoped>
.receive-record {
  padding: 50px 0 100px;
  .null {
    text-align: center;
    padding-top: 206px;

    &-icon {
      display: block;
      margin: 0 auto;
      width: 139px;
      height: 157px;
    }

    &-msg {
      padding-top: 37px;
      font-size: 28px;
      color: #af3e51;
    }
  }
  .box {
    // height: 266px;
    background: #ffffff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin: 0 32px 30px;
    padding: 24px 30px 30px;
    box-sizing: border-box;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;

      .title {
        img {
          width: 69px;
          padding-right: 24px;
        }
        .name {
          font-weight: 400;
          font-size: 34px;
          line-height: 48px;
        }
      }

      .more {
        .text {
          font-weight: 400;
          font-size: 26px;
          color: #999999;
          line-height: 37px;
        }

        img {
          width: 28px;
          padding-left: 6px;
        }
      }
    }

    &-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-text {
        .line {
          font-weight: 400;
          font-size: 26px;
          color: #999999;
          line-height: 37px;
          padding-bottom: 6px;
          display: flex;
          align-items: center;

          .copy {
            color: #4ea9fd;
          }

          .border {
            display: inline-block;
            width: 2px;

            height: 26px;
            background-color: #999999;
            margin: 0 11px 0 15px;
          }
        }
      }

      &-btn {
        .submit {
          width: 160px;
          height: 62px;
          line-height: 62px;
          background: #dcecff;
          border-radius: 31px;
          font-weight: 400;
          font-size: 28px;
          color: #4d9dff;
          padding: 0;
          border: 2px solid #4d9dff;
        }
      }
    }
  }
}
</style>
